import React, {useContext, createContext} from "react";
import PropTypes from "prop-types";
import {useNavigate, useParams} from "react-router";
import {useQuery} from "@apollo/client";
import {useRegScoutCore} from "../../../resources/RegScoutCoreProvider";
import {GET_REGULATION} from "../queries";
import {configureRegulation} from "../utils/dataTransformers";
import {isNull} from "underscore";

export const DetailDataContext = createContext({
  data: {},
  loading: true,
});

export const DetailDataProvider = ({children}) => {
  const {isPlatform} = useRegScoutCore();
  const {id} = useParams();

  const navigate = useNavigate();

  const {data, loading} = useQuery(GET_REGULATION, {
    variables: {id: parseInt(id, 10)},
    onCompleted: (data) => {
      if (isNull(data?.regulation)) {
        navigate(!isPlatform ? "/" : "/reports/research");
      };
    },
  });

  return (
    <DetailDataContext.Provider value={{
      data: loading || !data || isNull(data?.regulation) ? {} : {...configureRegulation(data?.regulation, data?.tagTypes)},
      loading,
    }}>
      {children}
    </DetailDataContext.Provider>
  );
};

DetailDataProvider.propTypes = {
  children: PropTypes.any,
};

export const useDetailData = () => {
  return useContext(DetailDataContext);
};

