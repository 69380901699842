import React, {useEffect, useRef, useState} from "react";
import {
  Avatar,
  Box,
  Button,
  Fab,
  IconButton,
  List,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import CloseIcon from "@mui/icons-material/Close";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import ChatbotLoading from "./ChatbotLoading";
import ChatbotMessage from "./ChatbotMessage";
import RexLogoDark from "~/public/rexLogoDark.png";
import RexLogoLight from "~/public/rexLogoLight.png";
import {useMutation} from "@apollo/client";
import {CHATBOT_RESPONSE} from "./queries";
import {getGreeting} from "./chatbotHelpers";
import {useTheme} from "../../resources/ConsolidatedThemeProvider";
import {map, isEmpty} from "underscore";
import {useChatbotStyles} from "./chatbotStyles";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const messageListRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [greetingShown, setGreetingShown] = useState(false);
  const [sessionId, setSessionId] = useState();
  const {classes} = useChatbotStyles();

  const {activeTheme} = useTheme();
  const isDarkTheme = activeTheme.palette.mode === "dark";
  const logo = isDarkTheme ? RexLogoDark : RexLogoLight;

  const [chatbotResponse] = useMutation(CHATBOT_RESPONSE, {
    onCompleted: (data) => {
      if (data?.chatbotResponse?.sessionId && sessionId !== data?.chatbotResponse?.sessionId) {
        setSessionId(data.chatbotResponse.sessionId);
      }
    },
  });

  const handleSend = async (e) => {
    e.preventDefault();
    if (isEmpty(input)) return;

    const newMessage = {text: input, sender: "Human"};
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");

    setLoading(true);

    try {
      const {data} = await chatbotResponse({
        variables: {
          question: input,
          sessionId,
        },
      });
      const botMessage = {
        text: data.chatbotResponse.response,
        references: data.chatbotResponse.references,
        sender: "Rex",
      };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
      setError("");
    } catch (error) {
      setError("Failed to get response from the server. Please try again later.");
    }
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (!greetingShown) {
      const greetingMessage = getGreeting();
      setMessages((prevMessages) => [...prevMessages, greetingMessage]);
      setGreetingShown(true);
    }
  };

  const handleEndConversation = async () => {
    setSessionId();
    const greetingMessage = getGreeting();
    setMessages([greetingMessage]);
    setGreetingShown(true);
  };

  useEffect(() => {
    if (open && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({behavior: "smooth"});
    }
  }, [messages, open]);

  return (
    <>
      <Fab
        color="primary"
        aria-label="chat"
        id="chat-with-rex"
        onClick={handleOpen}
        sx={{position: "fixed", bottom: 50, right: 50}}
      >
        {sessionId ? <MarkUnreadChatAltIcon /> : <ChatIcon />}
      </Fab>
      {open && (
        <Paper elevation={3} className={classes.chatbotPaper}>
          <Box className={classes.chatbotWrapper}>
            <Avatar src={logo} alt="BreachRx Logo" sx={{width: 50, height: 50}} />
            <Typography variant="h5" display="flex">
              Rex AI<Typography sx={{verticalAlign: "super"}}>&trade;</Typography>
            </Typography>
            <Box>
              <IconButton
                onClick={() => setOpen(false)}
                className={classes.chatbotIcon}
                sx={(theme) => ({marginLeft: theme.spacing(-5)})}
                id="minimize-chat"
                aria-label="minimize chat"
              >
                <Tooltip title="Minimize conversation" placement="top">
                  <CloseFullscreenIcon sx={{fill: "gray"}} />
                </Tooltip>
              </IconButton>
              <IconButton
                onClick={() => {
                  setOpen(false);
                  handleEndConversation();
                }}
                className={classes.chatbotIcon}
                id="close-chat"
                aria-label="close chat"
              >
                <Tooltip title="Close & end conversation" placement="top">
                  <CloseIcon sx={{fill: "gray"}} />
                </Tooltip>
              </IconButton>
            </Box>
          </Box>
          <List className={classes.chatbotMessageList} ref={messageListRef}>
            {map(messages, (message, index) => (
              <ChatbotMessage
                message={message}
                messageListRef={messageListRef}
                key={index}
              />
            ))}
            {loading && <ChatbotLoading />}
            <div ref={messagesEndRef} />
          </List>
          {error && (
            <Typography color="error" align="center">
              {error}
            </Typography>
          )}
          <Box
            component="form"
            onSubmit={handleSend}
            sx={{display: "flex", paddingTop: 2}}
          >
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Type a request..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              multiline
              InputProps={{
                sx: {
                  "& .MuiOutlinedInput-input": {
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                  },
                },
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e?.shiftKey) {
                  handleSend(e);
                }
              }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              id="send-chat"
              sx={{
                marginLeft: 1,
                borderRadius: 0,
              }}
            >
              Send
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={handleEndConversation}
            id="end-chat"
            color="primary"
            className={classes.chatbotEndButton}
          >
              End Conversation
          </Button>
        </Paper>
      )}
    </>
  );
};

export default Chatbot;
